import React from 'react';

import './Header.css';

import logo from '../../img/logo.png';
import headerBg from '../../img/headerbg.png';
import headerIconsLeft from '../../img/headericonsleft.png';
import headerIconsRight from '../../img/headericonsright.png';

const Header = () => {
    return (
        <div id="header" className="ui inverted" style={{backgroundImage: `url(${headerBg})`}}>
            <div className="ui inverted secondary menu">
                <a href="/" title="home">
                    <img className="ui image logo" src={logo} alt="Maw logo"/>
                </a>
                <div className="right menu">
                    <div className="beforeIcon" style={{backgroundImage: `url(${headerIconsLeft})`}}></div>
                    <div className="menu-wrapper">
                        <a href="https://www.linkedin.com/in/mawmawmaw/" target="_blank" rel="noreferrer" className="item" title="linkedin">
                            <i className="linkedin icon"></i>
                        </a>
                        <a href="https://github.com/mawmawmaw/" target="_blank" rel="noreferrer" className="item" title="github">
                            <i className="github icon"></i>
                        </a>
                        <a href="https://www.facebook.com/mawmawmawwebdevelopment" target="_blank" rel="noreferrer" className="item" title="facebook">
                            <i className="facebook icon"></i>
                        </a>
                        <a href="https://www.instagram.com/maw_maw_maw/" target="_blank" rel="noreferrer" className="item" title="instagram">
                            <i className="instagram icon"></i>
                        </a>
                        <a href="https://soundcloud.com/g_l_u_e_s_n_i_f_f_e_r" target="_blank" rel="noreferrer" className="item" title="soundcloud">
                            <i className="soundcloud icon"></i>
                        </a>
                    </div>
                    <div className="afterIcon" style={{backgroundImage: `url(${headerIconsRight})`}}></div>
                </div>
            </div>
        </div>
    )
}

export default Header;