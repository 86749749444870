import React, {useEffect} from 'react';

import './Welcome.css';

import border from '../../img/border.svg';
import border_dark from '../../img/border_dark.svg';
import border_red from '../../img/border_red.svg';
import border_blue from '../../img/border_blue.svg';
import maw_face from '../../img/maw/maw_talking.gif';

const Welcome = ({name, setName, setPass, mode, setMode}) => {

    const onNameSubmit = e => {
        e.preventDefault();
        document.querySelector("#clickSound").volume = 0.4;
        document.querySelector("#clickSound").play();
        if (name!==''){
            setPass(true);
        }else{
            document.querySelector('input#visitor').focus();
        }
    }

    const onNoNameSubmit = e => {
        e.preventDefault();
        document.querySelector("#clickSound").volume = 0.4;
        document.querySelector("#clickSound").play();
        setName('');
        setPass(true);
    }
    
    useEffect(()=>{
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            setMode('night')
        }else{
            setMode('day')
        }
    },[setMode])

    const handleModeChange = e => {
        if(mode==="day"){
            setMode("night")
        }else{
            setMode('day')
        }
    }

    return (
        <div id="welcome">
            <div className="welcome-wrapper" style={{borderImageSource: `url(${border})`}}>
                <div id="world-mode">
                    <div className="inline field">
                        <div className="ui toggle checkbox">
                            <label htmlFor="modeSwitch">Change mode</label>
                            <i className={`${mode==='day'?'moon':'sun'} icon`}></i>
                            <input type="checkbox" name="modeSwitch" id="modeSwitch" className="modeSwitch" onChange={handleModeChange}/>
                            <label></label>
                            <i className={`${mode==='day'?'sun':'moon'} icon`}></i>
                        </div>
                    </div>
                </div>
                <div className="welcome-header-wrapper">
                    <div className="avatar-head">
                        <img src={maw_face} alt="Maw"/>
                    </div>
                    <h1>Welcome</h1>
                </div>
                <h2>Would you please tell me your name?</h2>
                <p>It's not necessary but it will make a better experience!</p>

                <form onSubmit={onNameSubmit} className="ui form">
                    <div className="field">
                        <label htmlFor="visitor">Name</label>
                        <input type="text" name="visitor" id="visitor" placeholder="Type your name here..." onChange={e=>setName(e.target.value)} value={name} style={{borderImageSource: `url(${border_dark})`}}/>
                    </div>
                    <input type="submit" onClick={onNameSubmit} className="ui blue button" value="This is my name!" style={{borderImageSource: `url(${border_blue})`}}/>
                    <button onClick={onNoNameSubmit} className="ui button negative" style={{borderImageSource: `url(${border_red})`}}>I'd rather not...</button>
                    <span><small>By continuing, you accept the use of cookies to improve the user experience.</small></span>
                </form>
            </div>
        </div>
    )
}
export default Welcome;