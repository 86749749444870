import React, { useState, lazy, Suspense, useEffect } from 'react';
import Background from './Background/Background';
import Header from './Header/Header';
import Welcome from './Welcome/Welcome';

import './App.css';

const Content = lazy(() => import('./Content/Content'));

const App = () => {

    const [mode,setMode] = useState('');
    const [name,setName] = useState('');
    const [pass,setPass] = useState(false);
    const cookie = document.cookie.split('name=')[1] ? document.cookie.split('name=')[1].split(';')[0] : null;

    useEffect(()=>{
        console.log("%cHi! Nice to see you're curious too! :)","background-color:black;color: white; font-family:'VG','Lucida Console', sans-serif; font-size: 24px; font-weight: bold;text-shadow: 3px 3px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113)');");
        if(cookie && name===''){
            setName(document.cookie.split('name=')[1].split(';')[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{
        if(pass && name!=='' && cookie!==name){
            document.cookie = `name=${name}`;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[pass])

    return (
        <div id="mawmawmaw" className={mode}>
            <Background mode={mode}/>
            <Header />
            {pass || 
                <Welcome name={name} setName={setName} setPass={setPass} mode={mode} setMode={setMode}/>
            }
            {!pass || 
                <Suspense fallback={<div id="loader"><i className="spinner icon"></i></div>}>
                    <Content name={name} mode={mode}/>
                </Suspense>
            }
        </div>
    )
}

export default App;