import React, {useEffect} from 'react';

import './Background.css';

import bgGrass from '../../img/bg_grass.png';
import bgGrassDark from '../../img/bg_grass_dark.png';
import bgWater from '../../img/bg_water.png';
import bgWaterDark from '../../img/bg_water_dark.png';
import bgSky from '../../img/bg_sky.png';
import bgSkyDark from '../../img/bg_sky_dark.png';
import grass from '../../img/grass.png';

const Background = ({mode}) => {

    useEffect(()=>{
        document.querySelectorAll(".grass").forEach((f)=>{
            let rw = Math.floor(Math.random() * window.innerWidth) + 1;
            let rh = Math.floor(Math.random() * window.innerHeight) + (window.innerHeight/2);
            rh = (rh >= window.innerHeight || window.innerWidth <= 768) ? rh - 300 : rh; 
            f.style.top = rh+'px';
            f.style.left = rw+'px';
        })
    },[])

    const handleBackgrounds = () => {
        if( mode=== 'night' ){
            return (
                <div className="parallax-container">
                    <img src={grass} className="grass" alt="grass"/>
                    <img src={grass} className="grass" alt="grass"/>
                    <img src={grass} className="grass" alt="grass"/>
                    <img src={grass} className="grass" alt="grass"/>
                    <img src={grass} className="grass" alt="grass"/>
                    <img src={grass} className="grass" alt="grass"/>
                    <img src={grass} className="grass" alt="grass"/>
                    <img src={grass} className="grass" alt="grass"/>
                    <div id="parallax-layer-1" className="parallax-layer" style={{backgroundImage: `url(${bgGrassDark})`}}></div>
                    <div id="parallax-layer-2" className="parallax-layer" style={{backgroundImage: `url(${bgWaterDark})`}}></div>
                    <div id="parallax-layer-3" className="parallax-layer" style={{backgroundImage: `url(${bgSkyDark})`}}></div>
                </div>
            )
        }else{
            return (
                <div className="parallax-container">
                    <img src={grass} className="grass" alt="grass"/>
                    <img src={grass} className="grass" alt="grass"/>
                    <img src={grass} className="grass" alt="grass"/>
                    <img src={grass} className="grass" alt="grass"/>
                    <img src={grass} className="grass" alt="grass"/>
                    <img src={grass} className="grass" alt="grass"/>
                    <img src={grass} className="grass" alt="grass"/>
                    <img src={grass} className="grass" alt="grass"/>
                    <div id="parallax-layer-1" className="parallax-layer" style={{backgroundImage: `url(${bgGrass})`}}></div>
                    <div id="parallax-layer-2" className="parallax-layer" style={{backgroundImage: `url(${bgWater})`}}></div>
                    <div id="parallax-layer-3" className="parallax-layer" style={{backgroundImage: `url(${bgSky})`}}></div>
                </div>
            )
        }
    }

    return handleBackgrounds()
}

export default Background;